<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
    <div>
        <bread-crumbs :items="items" />
        <tabs-comp :tabs="tabs" />
        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-20px !important;`">
            <v-row class="mt-10">
                <v-col
                cols="12"
                md="10"
                sm="12"></v-col>
                <v-col cols="12" md="2" sm="12" class="text-end pt-7">
                    <b-button variant="success"  
                    class="btn-sm addemployeecl" 
                    :style="`width:145px;margin-`+lang.lalgin+`:6px;background:transparent;border:none`" flat @click="resetTitle()" 
                    v-b-toggle.add_employee 
                    id="multiply-button"
                    color="success">
                    <div style="width:150px;color:#000" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_employee}}</div>
                    </b-button>
                    <!-- <b-button variant="info" @click="ExportIt('e')" class="btn-sm" style="width:145px;margin-right:6px;">
                            تصدير اكسل
                    </b-button> -->
                    <!-- <b-button variant="danger" class="btn-sm" style="width:145px;margin-right:6px;">PDF تصدير</b-button> -->
                </v-col>
            </v-row>
        </v-row>
        <v-row style="margin:auto 30px;margin-top:-10px;">
            <v-col cols="12" :style="`direction: `+lang.dir+`;`">
                <v-data-table
                    :headers="headers"
                    :items="emprows"
                    :items-per-page="10"
                    class="elevation-1"
                    :page.sync="page"
                    hide-default-footer
                    @page-count="pageCount = $event"
                >
                <template v-slot:item="row">
                <tr>
                    <td style="width:100px" class="text-center">{{ row.item.mobile }}</td>
                    <td class="text-center" nowrap>{{ row.item.employee_name }}</td>
                    <td class="text-center">{{ row.item.id_number }}</td>
                    <td class="text-center">{{ row.item.salary }}</td>
                    <td class="text-center">{{ row.item.home_allown }}</td>
                    <td class="text-center">{{ row.item.tarns_allown }}</td>
                    <td class="text-center" nowrap>{{ row.item.food_allown }}</td> 
                    <td class="text-center" nowrap>{{ row.item.others_allown }}</td>
                    <td class="text-center">{{ row.item.gozi }}</td>
                    <td class="text-center">{{ row.item.percentage }}</td>
                    <td class="text-center">{{ row.item.ftotal }}</td>
                    <!-- <td style="padding-top:5px !important;padding-bottom:5px !important;" class="text-center">
                        <v-btn style="width:100px;font-size:0.2em;background:#000;color:#FFF;height:25px !important;margin-inline-end:5px;" @click="ViewItem(row.item)" v-b-toggle.add_employee>{{lang.update}}</v-btn>
                        <v-btn style="width:100px;font-size:0.2em;background:green;color:#FFF;height:25px !important;" @click="viewEmp(row.item)" v-b-toggle.view_employee>{{lang.view}}</v-btn>
                    </td> -->
                    <!-- <td style="margin-left: 5px;width:80px;background:red;border:2px solid #fff !important;border-top:2px solid #fff !important;">
                        <div class="updateBTN" v-b-toggle.add_employee @click="ViewItem(row.item)">
                        {{lang.salary_paid}}</div>
                    </td> -->
                    <td style="margin-left: 5px;width:80px;background:green;border:2px solid #fff !important;border-top:2px solid #fff !important;">
                        <div class="updateBTN" style="background:green !important" v-b-toggle.salary_paid @click="payForIt(row.item)">
                            {{ lang.salary_paid }}</div>
                    </td>
                    <td style="margin-left: 5px;width:80px;background:orange;border:2px solid #fff !important;border-top:2px solid #fff !important;">
                        <div class="updateBTN" style="background:orange !important;color:#000 !important" v-b-toggle.empl_ids @click="statForIt(row.item)">
                            {{ lang.client_statment }}</div>
                    </td>
                    <td style="margin-left: 5px;width:80px;background:red;border:2px solid #fff !important;border-top:2px solid #fff !important;">
                        <div class="updateBTN" v-b-toggle.add_employee @click="ViewItem(row.item)">
                        {{lang.update}}</div>
                    </td>
                    <td style="margin-left: 5px;width:80px;background:blue;border:2px solid #fff !important;border-top:2px solid #fff !important;">
                        <div class="viewBTN" v-b-toggle.view_employee @click="viewEmp(row.item)">
                        {{lang.view}}</div>
                    </td>
                </tr>
                </template>
                </v-data-table>
                <v-container style="direction:rtl">
                    <div class="text-center pt-2" style="direction:rtl">
                    <v-pagination
                        v-model="page"
                        value="10"
                        :length="pageCount"
                        style="direction:rtl"
                    ></v-pagination>
                    </div>
                </v-container>
            </v-col>
        </v-row>
        <addEmployee ref="addeditemp" />
    </div>
       <Footer />
       <employeeView ref="viewemployee" />
       <salaryPaid ref="salaryPaid" />
       <employeesStatement ref="employeesStatement" />
    </div>
</template>

<script>
import addEmployee from '@/components/addEmployee.vue'
import axios from 'axios';
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import employeeView from '@/components/employeeView.vue';
import salaryPaid from '@/components/salaryPaid.vue';
import employeesStatement from '@/views/employeesStatement.vue'
import ExportsPrint from '@/components/exports-print.vue'
export default {
  components: { ExportsPrint,employeesStatement,addEmployee, BreadCrumbs, TabsComp, HeaderPortrate, Footer,employeeView,salaryPaid },
    data() {
        return {
            curEmp: {},
            active_tab: 1,
            editemployee: 0,
            emprows: [ ],
            page: 1,
            pageCount:0,
            monthes:[
                'يناير', 'فبراير', 'مارس'
                , 'ابريل','مايو','يونيو'
                ,'يوليو','أغسطس','سبتمبر',
                'اكتوبر', 'نوفمبر' , 'ديسمبر'
            ],
            month: ''
        }
    },
    methods: {
        preparedata(){
            let exdata = {
                list: "employeesList",
                title: this.lang.employees,
                data: [],
            }
            for(let i=0;i<this.emprows.length;i++){
                exdata.data.push(this.emprows[i]);
            }
            // console.log(exdata);
            this.$refs.exportbar.exportdata = exdata;
            this.$refs.exportbar.sdate = '';
            this.$refs.exportbar.edate = '';
            
            this.$refs.exportbar.exporttitle = "";
            this.$refs.exportbar.palte_number = '';
            this.$refs.exportbar.contact_number = '';
        },
        statForIt(item){
            this.$refs.employeesStatement.accountn = item
            this.$refs.employeesStatement.getReport();
        },
        payForIt(item){
            this.$refs.salaryPaid.empid = item.id
            this.$refs.salaryPaid.getEmployees()
        },
        viewEmp(item){
            this.$refs.viewemployee.empid = item.id;
            this.$refs.viewemployee.employee = item;
            this.$refs.viewemployee.employees = item;
            this.$refs.viewemployee.getEmployees();
        },
        printMe(item){
            const post = new FormData();
            post.append('export','inrecepit');
            post.append('type',1);
            post.append('id',item.id);
        },
        ExportIt(type){
            let url = this.$store.state.SAMCOTEC.e_path+'?';
            if(type != 'e'){
                let url = this.$store.state.SAMCOTEC.pd_path+'?inrecepit';
            }
            const post = new FormData();
            post.append('export','inrecepit');
            post.append('type',1);
            post.append('sdate','');
            post.append('edate','');
            axios.post(
                url, post
            ).then((res) => {
                var rest = res.data;
                window.open('../api' + rest.url,'_blank')
            })
        },
        resetTitle()
        {
            this.$refs.addeditemp.user.startdate = this.$refs.addeditemp.date1;
            this.$refs.addeditemp.toptitle = 'اضافة موظف';
            this.$refs.addeditemp.resetValues();
        },
        ViewItem(item)
        {
            this.$refs.addeditemp.user = item;
            this.$refs.addeditemp.toptitle = 'تعديل موظف';
        },
        getSum(){
            let stable = this.emprows;
            for(var i in stable){
                let total = 0;
                total = +total + +stable[i].salary;
                total = +total + +stable[i].home_allown;
                total = +total + +stable[i].tarns_allown;
                total = +total + +stable[i].food_allown;
                total = +total + +stable[i].others_allown;
                this.emprows[i].ftotal = this.$RoundNum(total);
            }
        },
        getEmployees() {
            const post = new FormData();
            post.append("type" , "getEmployees")
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase))
            post.append("data[month]",this.month);
            axios.post(
                this.$SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                this.emprows = res.results.data.results;
            }).then(()=>{
                this.getSum();
            })
        }
    },
    created() {
        this.getEmployees();
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        upTitle: function(){
            return this.lang.add_employee;
        },
        tabs: function(){
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        items: function() {
            return {
                    text: this.lang.employees,
                    disabled: true,
                    to: '/finance/salaries',
                }
        },
        headers: function() {
            return [
                {
                    text: this.lang.mobile,
                    align: 'center',
                    sortable: true,
                    value: 'mobile',
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.emp_name,
                    align: 'center',
                    sortable: true,
                    value: 'employee_name',
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.id_number,
                    align: 'center',
                    sortable: true,
                    value: 'id_number',
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.basic_salary,
                    align: 'center',
                    sortable: true,
                    value: 'salary',
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.home_allownance,
                    align: 'center',
                    sortable: true,
                    value: 'home_allown',
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.trans_allownance,
                    align: 'center',
                    sortable: true,
                    value: 'tarns_allown',
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.food_allown,
                    align: 'center',
                    sortable: true,
                    value: 'food_allown',
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.other_allown,
                    align: 'center',
                    sortable: true,
                    value: 'others_allown',
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.goze_perc,
                    align: 'center',
                    sortable: true,
                    value: 'gozi',
                    class: 'pa-0 backBlack'
                },
                
                {
                    text: this.lang.percentate + ' %',
                    align: 'center',
                    sortable: true,
                    value: 'percentage',
                    class: 'pa-0 backBlack'
                },
                // {
                //     text: 'الخصم',
                //     align: 'center',
                //     sortable: true,
                //     value: 'deduct',
                // },
                {
                    text: this.lang.total,
                    align: 'center',
                    sortable: true,
                    value: 'ftotal',
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.salary_paid,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.client_statment,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.update,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.view,
                    class: 'pa-0 backBlack'
                }
            ]
        }
    },
}
</script>
<style>
.updateBTN {
    font-size: .8rem;
    background: red !important;
    width: 100%;
    color: #fff !important;
    height: 25px !important;
    margin-left: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
</style>