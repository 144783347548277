<template>
    <div class="px-3 py-2">
        <b-sidebar aria-labelledby="sidebar-no-header-title" width="700px" no-header id="salary_paid" backdrop :style="`direction:rtl`" right title="اضافة مصروف دوري" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" :style="`direction:`+$parent.lang.dir">
                    <div style="width:150px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span style="font-size:.8em;">{{$parent.lang.salary_paid}}</span>
                    </div>
                    <div @click="hide" id="xxhideExp" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{$parent.lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="px-3 py-2" :style="`direction:`+$parent.lang.dir">
                    <v-form ref="apform">
                        <div class="m-2 p-3 backRed">الرصيد السابق: {{ restSalary }}</div>
                        <v-row>
                            <v-col cols="12" md="6" sm="12">
                                <label>{{$parent.lang.payment_amount}}</label>
                                <b-form-input class="inborder"
                                    v-model="amount"
                                    :rules="fieldRules"
                                    ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="6" sm="12">
                                <label>{{$parent.lang.payment_type}}</label>
                                <b-input-group>
                                <b-form-select
                                    class="selborder"
                                    v-model="payment_type"
                                    :options="paytypes"
                                    :label="$parent.lang.payment_type"
                                    ></b-form-select>
                                <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                    <i class="fas fa-arrow-down"></i>
                                    </b-input-group-append>
                                </b-input-group>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
            </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" variant="success" @click="addit()" class="ma-2" style="width:100px;">{{$parent.lang.add}}</b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default{
    data() {
        return {
            empid: 0,
            amount: '',
            restSalary: '',
            payment_type: 1,
            month: '',
            year: '',
            fieldRules: [
                v => v > 0 || this.$parent.lang.mor_than_zero
            ],
            paytypes: [],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        }
    },
    computed: {
        installment_amount: function() {
            let t = 0;
            t = this.$RoundNum(parseFloat(this.amount) / parseFloat(this.installment_number));
            return t.toString() == "NaN" ? 0 : t;
        },
    },
    created(){
        this.getPyamentType();
    },
    methods: {
        getEmployees()
        {
            const post = new FormData();
            post.append("type" , "getAllEmp");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.empid);
            post.append('data[month]',this.month);
            post.append('data[year]',this.year);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                console.log("asdf",response.data);
                const res = response.data.results.data;
                this.empid = res[0].id;
                this.restSalary = res[0].restSalary;
                
            })
        },
        getPyamentType(){
            const post = new FormData();
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.payTypeOp = [];
                for(let i=0;i<res.results.data.length;i++){
                    this.paytypes.push({
                        text: this.$parent.lang.langname == 'ar' ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                        value: res.results.data[i].id
                    })
                }
            })
        },
        addit() {
            let vald = true;
            let message = '';
            if(this.amount == '' || this.amount <= 0){
                vald = false;
                message = this.$parent.lang.mor_than_zero;
            }
            if(!vald){
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append("type","addVouchter");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[amount]",this.amount);
            post.append("data[empid]",this.empid);

            post.append('data[mobile]',"")
            post.append('data[full_name]',"")
            post.append('data[payspan]',0)
            post.append('data[paycash]',0)

            post.append('data[docid]',this.empid);
            post.append('data[doc_type]',"salaries");
            post.append('data[type]',2);
            post.append('data[amount]',this.amount)
            post.append('data[paytype]',this.payment_type)
            post.append('data[notes]',"")
            post.append('data[date]',this.date)

            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                this.amount = '',
                document.getElementById('xxhideExp').click();
                this.$parent.getEmployees();

            })
        }
    },
}
</script>